@import url(https://fonts.googleapis.com/css?family=Spartan&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* main css */
body {
  font-family: 'Spartan', sans-serif;
}

.App {
  text-align: center;
  background-color: #000000;
}

/* App Body */
.App-body {
  background-color: #000000;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

#home, #about, #project {
  z-index: 100;
}

#about {
  margin: 200px;
}

#about ul {
  text-align: left;
}

#project {
  margin-top: 100px;
  margin-bottom: 150px;
}

#project h2 {
  margin: 10px;
}

#project a {
  color: #FFFFFF;
  text-decoration: none;
}

#canvas {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

/* nav-bar and footer css */
#nav-bar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  color: #FFFFFF;
  background-color: #000000;
  z-index: 110;
}

#nav-bar li {
  font-size: 30px;
}

#nav-bar-items li:hover, #learn-more:hover, #projectspan:hover {
  color: #FF0000;
  cursor: pointer;
}

#footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  color: #FFFFFF;
  background-color: #000000;
}

#social-items, #nav-bar {
  list-style: none;
}

#social-items li, #nav-bar li, #more-socials {
  display: inline-block;
  margin-right: 1rem;
  padding: 5px 10px 5px 10px;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

#social-items a {
  color: #FFFFFF;
}

#social-items a:hover, #more-socials:hover, .link-button:hover {
  color: #FF0000;
  cursor: pointer;
}

#more-socials {
  font-weight: bold;
}

#social-medias, #work-medias {
  display: inline-block;
  height: 30px;
}

/* animations */

.hidden {
  display: none !important;
}

.fadeIn {
  -webkit-animation: fadeIn .75s ease both;
          animation: fadeIn .75s ease both;
}

.fadeOut {
  -webkit-animation: fadeOut .75s ease both;
          animation: fadeOut .75s ease both;
}

@-webkit-keyframes fadeIn {
  0%   { opacity:0; }
  25%  { opacity:.25 }
  50%  { opacity:.5; }
  75%  { opacity:.75 }
  100% { opacity:1; }
}

@keyframes fadeIn {
  0%   { opacity:0; }
  25%  { opacity:.25 }
  50%  { opacity:.5; }
  75%  { opacity:.75 }
  100% { opacity:1; }
}

@-webkit-keyframes fadeOut {
  0%   { opacity:1; }
  25%  { opacity:.75 }
  50%  { opacity:.5; }
  75%  { opacity:.25 }
  100% { opacity:0; }
}

@keyframes fadeOut {
  0%   { opacity:1; }
  25%  { opacity:.75 }
  50%  { opacity:.5; }
  75%  { opacity:.25 }
  100% { opacity:0; }
}

@media only screen and (max-width: 470px) {
  #mobile-nav i {
    margin: 10px;
  }

  #mobile-nav-bar-items {
    display: none;
    padding: 0;
  }

  #mobile-nav-bar-items li {
    display: block;
  }

  #about, #project {
    margin-left: 10px;
    margin-right: 10px;
  }

  #project {
    margin-top: 50px;
  }
}

/* @keyframes slideInTop {
  from {
    margin-bottom: 100%;
  }

  to {
    margin-bottom: 0%;
  }
}

@keyframes slideInBottom {
  from {
    margin-top: 100%;
  }

  to {
    margin-top: 0%;
  }
}

@keyframes slideInLeft {
  from {
    margin-left: 100%;
  }

  to {
    margin-left: 0%;
  }
}

@keyframes slideInRight {
  from {
    margin-right: 100%;
  }

  to {
    margin-right: 0%;
  }
} */
